import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const pickRandom = (arr) => arr[Math.floor(Math.random()*arr.length)];

const Splash = ({ children, colour }) => {

  const query = useStaticQuery(graphql`
    {
      craft {
        categories(group: "Colours") {
          ... on Craft_colours_Category {
            colour
          }
        }
      }
    }
  `)

  const background = colour || pickRandom(query.craft.categories).colour

  return (
    <div className="splash-page" style={{
      backgroundColor: background
    }}>
      {children}
    </div>
  )
}

export default Splash
