import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
// ---------------------------
import { Link } from "../components/transition"
import { Logo, MartLogo } from "../components/svgs"

const Menu = ({
  handleLink,
  setMenuOpen,
  showLinks,
  setShowLinks,
  closeMenu,
}) => {
  const [winH, setWinH] = useState("100vh")

  const sustainabilityPolicyQuery = useStaticQuery(graphql`
    query SustainabilityPolicyMenuQuery {
      craft {
        entries(type: "Pages", slug: "sustainability-policy") {
          ... on Craft_pages_pages_Entry {
            showInMenu
            title
          }
        }
      }
    }
  `)

  // check if sustainabilityPolicyQuery.craft.entries has a length
  const sustainabilityPolicyEntry = {}
  sustainabilityPolicyEntry.showInMenu = false
  sustainabilityPolicyEntry.title = ""
  if (sustainabilityPolicyQuery.craft.entries.length) {
    sustainabilityPolicyEntry.showInMenu =
      sustainabilityPolicyQuery.craft.entries[0].showInMenu
    sustainabilityPolicyEntry.title =
      sustainabilityPolicyQuery.craft.entries[0].title
  }

  useEffect(() => {
    setShowLinks(true)
  })

  const handleResize = () => {
    setWinH(`${window.innerHeight}px`)
  }

  useEffect(() => {
    handleResize() // On init...
    window.addEventListener("resize", handleResize, false)
    return () => {
      window.removeEventListener("resize", handleResize, false)
    }
  }, [])

  return (
    <div
      className={`main-menu innerx2 ${showLinks ? "show-links" : ""}`}
      onMouseLeave={closeMenu}
    >
      <ul
        style={{
          minHeight: `calc(${winH} - 10vh)`,
        }}
      >
        <li className="first-label">
          <span>MGMT.</span>
        </li>
        <li>
          <Link to={`/`} handleLink={handleLink}>
            Artists
          </Link>
        </li>
        <li>
          <Link to={`/news`} handleLink={handleLink}>
            News
          </Link>
        </li>
        <li>
          <Link to={`/production`} handleLink={handleLink}>
            Production
          </Link>
        </li>

        {/* hide from menu if showInMenu is true */}
        {sustainabilityPolicyEntry.showInMenu && (
          <li>
            <Link to={`/sustainability-policy`} handleLink={handleLink}>
              {sustainabilityPolicyEntry.title}
            </Link>
          </li>
        )}
        <li>
          <Link to={`/contact`} handleLink={handleLink}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

const Header = ({ mart, handleLink }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [showLinks, setShowLinks] = useState(false)

  const closeMenu = () => {
    setMenuOpen(false)
    setShowLinks(false)
  }

  const handleMouseEnter = () => {
    setMenuOpen(true)
  }

  const handleTouchEnd = () => {
    if (menuOpen) {
      closeMenu()
    } else {
      setMenuOpen(true)
    }
  }

  return (
    <header
      className={`navigation ${mart ? "is-mart" : ""} ${
        menuOpen ? "show" : "hide"
      }`}
    >
      <nav className="col padx2">
        <button
          className={`menu-btn__mouse ontop ${showLinks ? "show-links" : ""}`}
          onMouseEnter={handleMouseEnter}
        />
        <button
          className={`menu-btn__touch ontop ${showLinks ? "show-links" : ""}`}
          onTouchEnd={handleTouchEnd}
        />
        {menuOpen && (
          <Menu
            handleLink={handleLink}
            setMenuOpen={setMenuOpen}
            showLinks={showLinks}
            setShowLinks={setShowLinks}
            closeMenu={closeMenu}
          />
        )}
      </nav>

      <div className="col-middle logo align-center padx2 ontop">
        <Link to={mart ? "/mart/" : "/"} handleLink={handleLink}>
          {mart ? <MartLogo /> : <Logo />}
        </Link>
      </div>

      <div className="col align-right padx2 ontop">
        <Link to={"/contact"} handleLink={handleLink}>
          Contact
        </Link>
      </div>
    </header>
  )
}

export default Header
