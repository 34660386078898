import React, { useState, useContext, useEffect } from "react";
import { LightboxContext } from "./store";
import { Link } from '../../helpers/transitions';
// -------------------------------

const LightboxLink = ({ handleLink, className }) => {

  const [lightbox] = useContext(LightboxContext);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(lightbox ? lightbox.length : 0)
  }, [lightbox])

  return lightbox.length > 0 && (
    <div className={`lightbox-link ${className ? className : ''}`}>
      <span className="lightbox-link__text heading--4">
        <Link to="/lightbox" handleLink={handleLink}>
          {`Lightbox (${counter})`}
        </Link>
      </span>
    </div>
  );
};

export default LightboxLink;
