import React, { useEffect, useState, useRef } from "react";
import { Link as GatsbyLink, navigate } from "gatsby";

const TransitionText = ({ props, children, hideTransitionText, speed = 800 }) => {

  let timer;
  const el = useRef();
  const [visible, setVisible] = useState(true);

  const hideText = () => {
    setVisible(false)
  }

  // NOTE: When mounted, fade in
  useEffect(() => {
    if (hideTransitionText) {
      el.current.addEventListener('transitionend', hideTransitionText)
    }
    timer = setTimeout(hideText, speed * 2)
    return () => {
      clearTimeout(timer)
      if (hideTransitionText) {
        el.current.removeEventListener('transitionend', hideTransitionText, false)
      }
    }
  }, [])

  return (
    <div className="pfix fs" ref={el} style={{
      opacity: visible ? 1 : 0,
      transition: `opacity ease-in-out ${speed}ms`,
      zIndex: 2000
    }}>
      {children}
    </div>
  )
}

const Transition = ({ props, render, textComponent, splashSpeed }) => {

  let timer;
  const speed = 600;
  const [visible, setVisible] = useState(false);
  const [hasText, setHasText] = useState(textComponent ? true : false);
  const [inProgress, setProgress] = useState(false);
  const [path, setPath] = useState(props.location.pathname);

  const handleLink = (to, event) => {
    !event || event.preventDefault();
    if (to !== path) {
      setVisible(false)
      setProgress(true)
      setPath(to)
    }
  }

  const changePage = () => {
    clearTimeout(timer);
    // setVisible(true)
    setProgress(false)
    navigate(path)
  }

  const showPage = () => {
    setVisible(true)
  }

  const hideTransitionText = () => {
    setHasText(false)
  }

  useEffect(() => {
    if (inProgress) {
      timer = setTimeout(changePage, speed);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [path])

  // NOTE: When mounted, fade in
  useEffect(() => {
    timer = setTimeout(showPage, 200)
    return () => {
      clearTimeout(timer)
    }
  }, [hasText])

  return (
    <div style={{
      opacity: visible ? 1 : 0,
      transition: `opacity ease-in-out ${speed}ms`
    }}>
      {hasText && (
        <TransitionText props={props} speed={splashSpeed || speed} hideTransitionText={hideTransitionText}>
          {textComponent()}
        </TransitionText>
      )}
      {render(handleLink)}
    </div>
  )
}

export const Link = ({ to, className, children, handleLink }) => handleLink ? (
  <GatsbyLink to={to} className={className} onClick={handleLink.bind(this, to)}>
    {children}
  </GatsbyLink>
) : (
  <GatsbyLink to={to} className={className}>
    {children}
  </GatsbyLink>
)

function withTransition(Component, textComponent, splashSpeed) {
  return class extends React.Component {
    render() {
      return (
        <Transition props={this.props} splashSpeed={splashSpeed} textComponent={textComponent} render={handleLink => (
          <Component {...this.props} handleLink={handleLink} />
        )} />
      );
    }
  }
}

export default withTransition
