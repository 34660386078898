import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useIdle } from "react-use"
// ---------------------------------------------
import Header from "../components/header"
import Layout from "../components/layout"
import LightboxLink from "../components/lightbox/link"
import { Logo } from "../components/svgs"
import Picture from "../components/picture"
import Splash from "../components/splash"
import Transition, { Link } from "../components/transition"
import Vimeo from "../components/vimeo"
import SEO from "../components/seo"

const Intro = () => (
  <Splash>
    <Logo />
  </Splash>
)

function useResize() {
  const [winH, setWinH] = useState("100vh")

  useEffect(() => {
    const handleResize = () => {
      setWinH(`${window.innerHeight}px`)
    }
    window.addEventListener("resize", handleResize, false)
    return () => {
      window.removeEventListener("resize", handleResize, false)
    }
  }, [])

  return [winH]
}

const Artist = props => {
  return (
    <div className={`artist ${props.isActive ? "active" : ""}`}>
      <div className={`homepage-images`}>
        {props.thumbnails.map((block, blockKey) => {
          return block.typeHandle === "video" ? (
            <div
              key={`video-${blockKey}-${props.id}`}
              className="homepage-images__image"
            >
              <Vimeo {...block} />
            </div>
          ) : (
            block.source.map(asset => {
              return (
                <div key={asset.id} className="homepage-images__image">
                  <div className="image-thumb is-loaded">
                    <Picture {...asset} resize={{ height: 320 }} />
                  </div>
                </div>
              )
            })
          )
        })}
      </div>
      <div className="artist-name uppercase">
        <Link
          to={`/${props.slug}`}
          className="artist-name__title"
          handleLink={props.handleLink}
        >
          {props.title}
        </Link>
        {/* if the roles have a lentgh, iterate over it  */}
        {props.roles.length > 0 && (
          <div className="artist-name__roles">
            {props.roles.map((role, index) => (
              <span key={index}>
                {role.title}
                {index < props.roles.length - 1 && ", "}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function useListCarousel(length, speed, isIdle) {
  const [index, set] = useState(0)

  useEffect(() => {
    let timer

    const progress = () => {
      set(prev => {
        return prev === length ? 0 : prev + 1
      })
      timer = setTimeout(progress, speed)
    }

    if (isIdle) {
      timer = setTimeout(progress, speed)
    } else {
      clearTimeout(timer)
      set(0)
    }

    return () => clearTimeout(timer)
  }, [isIdle])

  return index
}

const Artists = ({ artists, handleLink }) => {
  const [winH] = useResize()
  const isIdle = useIdle(5000)
  const activeIndex = useListCarousel(artists.length, 3000, isIdle)

  return (
    <div
      className={`index__artists ${isIdle ? "idle" : ""}`}
      style={{
        minHeight: `calc(${winH} - 6rem)`,
      }}
    >
      <div className={`block homepage-artists `}>
        {artists.map(
          (node, index) =>
            node.isVisible && (
              <Artist
                {...node}
                isActive={activeIndex === index}
                handleLink={handleLink}
                key={node.id}
              />
            )
        )}
      </div>
    </div>
  )
}

const IndexPage = ({ data, location }) => {
  const artists = data.craft.entries
  const startpage = data.craft.startpage[0]

  return (
    <Layout>
      <SEO />
      <Transition
        location={location}
        intro={Intro}
        introKey={"homeIntro"}
        render={(handleLink, hasIntro) => (
          <>
            <LightboxLink handleLink={handleLink} />
            <Header handleLink={handleLink} />
            <style>
              {`.artist .artist-name__title {
                font-size: ${startpage.fontsizeMobile}vw;
              }
              @media (min-width: 768px) {
                .artist .artist-name__title {
                  font-size: ${startpage.fontsizeDesktop}vh;
                }
              }
              `}
            </style>
            {!hasIntro && <Artists artists={artists} handleLink={handleLink} />}
          </>
        )}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    craft {
      startpage: entries(type: "Startpage") {
        ... on Craft_startpage_startpage_Entry {
          fontsizeMobile
          fontsizeDesktop
        }
      }
      entries(type: "Artists") {
        ... on Craft_artists_artists_Entry {
          id
          title
          slug
          isVisible
          roles {
            title
          }
          thumbnails {
            ... on Craft_thumbnails_images_BlockType {
              id
              typeHandle
              source {
                ... on Craft_minkAssets_Asset {
                  id
                  url
                  width
                  height
                  path
                  mimeType
                }
              }
            }
            ... on Craft_thumbnails_video_BlockType {
              id
              typeHandle
              vimeoUrl
              videoWidth
              videoHeight
              startTime
              endTime
            }
          }
        }
      }
    }
  }
`

// export const assetFragment = graphql`
//   fragment Asset on Craft_SpacesVolume {
// id
// kind
// url
// width
// height
// title
// folder {
//   path
// }
// filename
// url
//   }
// `
